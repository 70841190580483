import React, { FC, useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { subscriptionContext } from '../../../components/context/subscription/SubscriptionContext';
import {
  NetworkStatus,
  useCombinedNetworking,
} from '../../../hooks/useCombinedNetworking/useCombinedNetworking';
import style from './priceAcceptPage.module.scss';
import {
  PButton,
  PHeading,
  PIcon,
  PSpinner,
  PTag,
  PText,
} from '@porsche-design-system/components-react';
import { FrostedLayout } from '../../../components/layout/frosted/FrostedLayout';
import { messages } from './priceAcceptPage.messages';
import { FormattedMessage } from 'react-intl';
import background from '../../../media/backgroundPriceUpdate.png';
import { InlineTariff } from 'components/tariff/inline/InlineTariff';
import { AdditionalDisclaimer } from 'pages/change/select/additionalDisclaimer/AdditionalDisclaimer';
import { useSpecificPlans } from 'hooks/useSpecificPlans';
import { useNavigate } from 'react-router-dom';
import { useRoutes } from 'hooks/useRoutes';
import { Routes } from 'router/routes';
import { useShouldShowPriceUpdate } from 'hooks/useShouldShowPriceUpdate';

export const PriceAcceptPage: FC = observer(() => {
  const navigate = useNavigate();
  const generateRoute = useRoutes();
  const subscription = useContext(subscriptionContext);
  const { plans, isLoading: isLoadingPlans } = useSpecificPlans({
    planVariant: subscription.subscription?.currentOrLastPhase.plan.variant,
  });

  const { shouldShowPriceUpdate, dateOfPriceUpdate } = useShouldShowPriceUpdate(
    { subscription: subscription?.subscription },
  );

  const formattedDate =
    dateOfPriceUpdate &&
    Intl.DateTimeFormat('default', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    }).format(new Date(dateOfPriceUpdate));

  const priceUpdatePlan = useMemo(() => {
    if (!plans || plans.length < 2) {
      return null;
    }

    const newestPlan = plans[0];
    return subscription.subscription?.currentOrLastPhase.plan.pcsVersion &&
      newestPlan.pcsVersion >
        subscription.subscription?.currentOrLastPhase.plan.pcsVersion
      ? newestPlan
      : null;
  }, [plans, subscription.subscription]);

  const network = useCombinedNetworking([subscription.network]);

  const loadingText = useMemo(() => {
    if (subscription.network !== NetworkStatus.Success) {
      return <FormattedMessage {...messages.loadingSubscription} />;
    }
    return <FormattedMessage {...messages.loadingTariffs} />;
  }, [subscription.network]);

  return (
    <FrostedLayout background={background}>
      {network === NetworkStatus.Loading || isLoadingPlans ? (
        <div className={style.center}>
          <PSpinner />
          <PText>{loadingText}</PText>
        </div>
      ) : null}

      {network === NetworkStatus.Error || subscription.subscription === null ? (
        <div className={style.center}>
          <PIcon
            name={'close'}
            size={'large'}
            aria={{ 'aria-label': 'Close icon' }}
          />
          <PText size={'medium'}>
            <FormattedMessage {...messages.errorTitle} />
          </PText>
          <PText size={'small'}>
            <FormattedMessage {...messages.errorText} />
          </PText>
        </div>
      ) : null}

      {!subscription.subscription ? (
        <div className={style.center}>
          <PIcon
            name={'check'}
            size={'large'}
            aria={{ 'aria-label': 'Check icon' }}
          />
          <PText size={'medium'}>
            <FormattedMessage {...messages.unavailableTitle} />
          </PText>
          <PText size={'small'}>
            <FormattedMessage {...messages.unavailableText} />
          </PText>
        </div>
      ) : null}

      {subscription &&
      subscription.subscription &&
      shouldShowPriceUpdate &&
      formattedDate ? (
        <div className={style.contentContainer}>
          <PHeading>
            <FormattedMessage {...messages.title} />
          </PHeading>

          <PText>
            <FormattedMessage
              {...messages.subTitle}
              values={{
                date: formattedDate,
              }}
            />
          </PText>

          <InlineTariff
            plan={subscription.subscription.currentOrLastPhase.plan}
            marketplace={subscription.subscription.country}
            removeDisclaimer={true}
            newPriceUpdatePlan={priceUpdatePlan}
          />

          <PTag icon="chat" color="notification-info-soft">
            <FormattedMessage
              {...messages.updateDateNotificationText}
              values={{
                date: formattedDate,
              }}
            />
          </PTag>

          <div>
            <PButton onClick={() => navigate(generateRoute(Routes.Overview))}>
              <FormattedMessage {...messages.actionButtonLabel} />
            </PButton>
          </div>

          <AdditionalDisclaimer />
        </div>
      ) : null}
    </FrostedLayout>
  );
});
